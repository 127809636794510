@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.queue-block {
    background: #fff;
    padding: 18px 14px; 
    box-shadow: 0 0 10px 0 #00000017;
    margin: 30px 0;
}
.queue-block h1 {
    font-size: 24px;
    color: #003b5b;
    font-weight: 600;
    margin: 0 0 16px 0;
}
.queue-inner {
    background: #f6fbff;
    border: solid 1px #d5e1eb;
    border-radius:5px;
    padding: 14px 8px;
    text-align: center;
}
.queue-inner.ui.form input[type="text"] {
    width: 60%;
    text-align: center;
    box-shadow: 3px 3px 4px 0px #00000017;
    font-size: 16px;
    color: #003b5b;
    padding: 8px 0;
    border-radius:8px;
}
.queue-inner h3 {
    background: #003b5b;
    border-radius: 5px;
    min-height: 58px;
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    padding: 10px 5px;
    margin: 10px 0 0 0;
    border-radius:8px;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    display: flex;
}

.tabs-outer .ui.menu .item:before {
    display: none;
}
.tabs-outer .ui.menu .item {
    margin-right: 1px;
    background: #45b1ef;
    border-radius: 8px 8px 0 0;
    font-size: 17px;
    color: #fff;
    font-weight: 600;
    padding: 1.1rem 2.5rem;
}
.tabs-outer .ui.menu .active.item {
    background: #003b5b;
}
.tabs-outer .ui.menu a.item:hover {
    background:#003b5b;
    color: #fff;
}

.tabs-outer .tab-content-area {
    border: solid 2px #ddd;
   /* border: solid 2px #003b5b; */
}

.tab-content-area .ui.table thead th {
    background: #dff2ff;
    font-weight: 500;
    color: #003b5b;
}

.tab-table tr td {
    padding: .5rem 1rem !important;
}
.tab-table tr td:first-child {
    width:15%;
}
.tab-table tr td:last-child {
    text-align: center;
    width: 15%;
}
.blue-btn {
    background: #003b5b;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    padding:.7rem 1rem;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}
.blue-btn:hover {
    background: #45b1ef;
}
.table tbody + thead {
    display: none;
}
.tabsouterCustom select {
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: 3px 3px 4px 0px #00000017;
    padding: 10px !important;
    border-radius: 4px;
}
.btnControl {
    width: 25px !important;
    height: 25px !important;
    padding: 0 !important;
    border-radius: 30px !important;
    margin-right: 5px !important;
}
.btnControl:last-child {
    margin-right: 0px !important;
}
.btnControl div {
    margin: 0 !important;
}
.btnControl .MuiSvgIcon-root {
    font-size: 1rem !important;
}
.btnControl.btndel { 
    background: #d61313 !important;
    border: 1px solid #d61313 !important;
}
.btnControl.btnsv{
    background: #087208 !important;
    border: 1px solid #087208 !important;
}

.customSelectIcon .custom-select {
    margin-left: 0.5rem !important;
    background-image: url(https://cdn.iconscout.com/icon/free/png-256/angle-down-arrow-direction-path-way-44424.png) !important;
    width: 64px !important;
    background-position: center right !important;
    background-size: 24px !important;
    background-repeat: no-repeat !important;
}

.table-bordered  .btn-primary {
    margin:0;
}

.patientHeaderButton button {
    margin-right: 7px !important;
    margin-left: 0 !important;
}

textarea {
    resize: none;
}

.f-12 {
   font-size: 12px !important;
}

@media only screen and (max-width: 767px){
    .customImport {
        margin-top: 15px;
     }
}
.dataTables_wrapper{
    white-space: nowrap;
}
.nowrap{
    white-space: nowrap;
}
.importInput input {
    display: inline-block;
    background: #fff;
    padding: 10px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: 3px 3px 4px 0px #00000017;
    margin: 0 20px 0 0;
    border-radius: 6px;
}

.task-count {
    border: 2px solid #eee;
    background: #fff;
    border-radius: 8px;
    padding: 7px 35px;
    box-shadow: 2px 2px #eee;
    margin: auto;
    width: 60%;
}
.ui.menu.top.stackable select{width: 100%;}
.ui.menu.top.stackable button{width: 100%;}
.headingmaintop{padding: 15px 0;}
.ui.menu.top.stackable.bordermain {
    border: 1px solid #e2e2e2;
    padding: 15px 0;
}
.table.table-bordered.table-hover.table-sm.table-striped.dataTable {
    width: 100% !Important;
}
