.login-bg {
  background: #003b5c !important;
  height:100% !important;
  height:100vh !important;
}
.login-bg-fix{

 /*   min-height: 82vh;*/
/* height:645px;*/
 position: relative;
  background-color: #fff;
  width: 80%;
  margin: auto;
  margin-top: 100px;
  border-radius:10px;
  box-shadow:1px 1px 10px #b5b5b5;
}

.login-bg-fix .ui.grid .ten.wide.column {
  padding: 0;
}

.login-inner-form{
  text-align: center;
  width: 100%;
  /*height: 620px;*/
  margin-left: 26px;
}

.logo-box img {
  padding-top: 35px;
}

.login-heading{
  color:#45B2EF;
  font-size: 30px;
  text-align: left;
  font-weight: normal;
  padding-bottom: 15px;
}

.inner-form-design{
  text-align:left;
}

.inner-form-design input{
 border-top:transparent !important;
 border-right:transparent !important;
 border-left:transparent !important;
}

.inner-box-login {
  padding:10px;
}

.forget-link{
  padding:10px 0px;
}

.field span {
  padding: 0px 5px 0px 5px !important;
}

.login-btn{
  background-color: #003B5C !important;
  color: #fff;
  width: 100%;
  margin-top: 25px !important;
  height: 42px;
  border-radius: 10px !important;

}

.login-btn:hover{
  background-color: #1678c2 !important;
  color: #fff;
}

.ui.form.forget-link a {
  color: #585858;
  font-size: 12px;
  padding-left: 5px;
}

.icon-right{
 float:right;
}

.create-txt{
 color: #45B2EF;
}

.account-txt{
  text-align:left;
  text-align: left;
  position: absolute;
  left: 50px;
  bottom: 35px;
}


@media only screen and (max-width: 767px){

.login-bg-fix {
  
  height: auto;
  position: relative;
  background-color: #fff;
  width: 100%;
  margin: auto;
  
  border-radius: 10px;
  
}
.background-img{

display:none;
}
.login-inner-form {
  text-align: center;
  width: 100%;
  height: 620px;
  margin-left:10px;
}
.logo-box img {
  padding-top: 10px;
}
.login-inner-form {
  text-align: center;
  width: 100%;
  height: 95vh;
  margin-left: 10px;
}
.logo-box{

position:relative;
left:-20px;
}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {


.five.wide.column.ipad-fix.tab.only {
  width: 94% !important;
}
.login-btn {
  background-color: #003B5C !important;
  color: #fff;
  width: 100%;
  margin-top: 25px !important;
  height: 42px;
  border-radius: 10px !important;
}

.login-inner-form {
  text-align: center;
  width: 100%;
  height: 515px;
  margin-left: 26px;
}
}
