@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
  }
  body{
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
  }
/* Top Header Start**/
/**********************************************************/
/**********************************************************/
/**********************************************************/
header {
    background: #fff;
    position: fixed;
    padding: 0 10px 0 0;
    left: 0;
    right: 0;
    z-index: 99999;
    box-shadow: 0 0 10px 0 #00000017;
    height: 50px;
}

.header-logo {
    width: 240px;
    height: 50px;   
    position: relative;
    padding: 5px;
    background: #003b5b;
    z-index: 99999;
}

.header-logo img {
    width: auto !important; 
    max-width: 240px;
    height: 50px;
}

.header-inner {
    display: flex;
    align-items: center;
}

.tgl-btn {
    margin: 0 0 0 20px;
    flex-grow: 1;
}

.tgl-btn img {
    width:40px;
}

.hide-nav .sidebar-wrapper {
    width:50px;
}

.hide-nav #wrapper {
    padding-left: 70px;
}

.header-right {
    display: flex;
    align-items: center;
}

.search-panel {
    margin: 0 20px 0 0;
}

.ui.form .search-panel input[type="text"] {
    border-radius:30px;
}

.user-profile i.fa-user {
    background: #45b1ef;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50px;
    text-align: center;
    font-size: 17px;
    color: #fff;
}
/* Top Header End**/
/**********************************************************/
/**********************************************************/
/**********************************************************/