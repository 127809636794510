@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
}
div #one {
  background: #f6fbff;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
.text-blue {
  color: #007bff;
}
.bg-gray {
  background: #f3f2f2;
}
.user-icon {
  height: 40px;
  width: 40px;
  line-height: 36px;
  border: 2px solid;
  border-radius: 30px;
}
h3 {
  font-size: 1.28571429rem;
}

.btnControl {
  width: 25px !important;
  height: 25px !important;
  padding: 0 !important;
  border-radius: 30px !important;
  margin-right: 5px !important;
}
.btnControl:last-child {
  margin-right: 0px !important;
}
.btnControl div {
  margin: 0 !important;
}
.btnControl .MuiSvgIcon-root {
  font-size: 1rem !important;
}
.btnControl.btndel {
  background: #d61313 !important;
  border: 1px solid #d61313 !important;
}
.btnControl.btnsv {
  background: #0db10d !important;
  border: 1px solid #0db10d !important;
}
textarea {
  resize: none;
  text-align: center;
}
.table {
  overflow: auto;
  white-space: nowrap;
  min-width: 1150px;
}
.ae_grade:hover{
  text-decoration: underline;
  color: #4285f4;
}
.table > tbody > tr > td {
  text-align: center;
  font-size: 0.9rem;
}
.unit-label {
  text-align: right;
  width: 100%;
  font-size: 10px;
}
.shadow-box {
  background: #fff;
  padding: 10px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 3px 3px 4px 0px #00000017;
  margin-left: 15px;
}
.hr-blue {
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #73b4da !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.commonTerminology h3 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 50px;
}
.commonTerminology h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}
.grade5 {
  margin-bottom: 30px;
  margin-top: 30px;
}
.commonTerminology h5 {
  font-weight: bold;
  margin-bottom: 0;
}

.commonTerminology p.size1 {
  font-size: 14px;
}

.commonTerminology p.size2 {
  font-size: 15px;
}

.commonTerminology .grades ul li {
  display: flex;
  margin-bottom: 20px;
}

.commonTerminology .grades ul {
  padding-left: 50px;
  margin-bottom: 50px;
}

.commonTerminology .grades ul li b {
  width: 80px;
  min-width: 80px;
}

.gridTable select.form-control {
  width: 160px;
}

.gridTable textarea.form-control {
  width: 350px;
  height: 60px;
}

.gridTableLastUpdated {
  width: 180px;
}
.paddingleft{
  padding-left: 0px;
}
.paddingright{
  padding-right: 0px;
}
.paddingzero{
  padding:0px;
}

.divspace{margin:10px 0px;}