@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
  }
  body{
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
  }
/* Sidebar Start**/
/**********************************************************/
/**********************************************************/
/**********************************************************/

#wrapper {
    position: relative;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    padding-left: 260px;
    padding-right:20px;
    padding-top: 70px;
    padding-bottom: 55px;
    background: #f6fbff;
    /* height: 900px; */
}
.sidebar-wrapper {
    width: 240px;
    height: 100%;
    z-index: 1000;
    position: fixed;
    overflow-y: auto;
    background: #003b5b;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    top: 50px;
    bottom: 0px;
    left: 0px;;
}
.sidebar-nav {
    padding: 20px 0 0 0;
}
.sidebar-nav ul {
    margin: 0px;
    padding:0px;
}
.sidebar-nav ul li {
    list-style: none;
    display: block;
}
.sidebar-nav ul li a {
    font-size: 14px;
    color: #fffffd;
    padding: .8rem 0 .8rem 3.6rem;
    margin-bottom: 2px;
    display: block;
    position: relative;
    white-space: nowrap;
}
.sidebar-nav ul li a.active, .sidebar-nav ul li a:hover {
    background: #45b1ef;
}
.sidebar-nav ul li a span {
    position: absolute;
    left: 12px;
    top: 13px;
    font-size: 18px;
}


.expandBody .sidebar-wrapper {
    left: -240px;
}

.expandBody #wrapper {
   padding-left: 20px;
}

.patientPageContainer {
    min-height: calc(100vh - 40px);
}

@media only screen and (max-width: 767px){
.logo-box{

	position:relative;
	left:-20px;
}
.sidebar-wrapper {
    width: 0;
}
.hide-nav .sidebar-wrapper {
    width:240px;
}
    #wrapper {
        padding-left: 0px;
    }
    .expandBody .sidebar-wrapper {
        left: 0;
    }

}
.subMenu{
    min-width: 98.3%!important;
    background: #02283c;
}
.sidebar-nav ul li .subMenu a {
    font-size: 14px;
    color: #fff;
    border-bottom:1px solid #003b5b;
}
.sidebar-nav ul li .subMenu a:hover{
    background: #45b1ef;
    color:#fff;
}
.dropdown-menu.subMenu{
    background-color: #011d2c!important;
}
.dropdown-menu.subMenu a{
    color:#fff;
}
/* Sidebar End**/
/**********************************************************/
/**********************************************************/
/**********************************************************/