.marginzero {
  margin: 0px !important;
}

#customStyles {
  width: 25%;
  height: 25%;
  text-align: center;
  position: absolute;
  left: 35%;
  top: 25%;
}

#medication_label {
  font-size: large;
}
