
a:hover{
  text-decoration: none;
  color:#c3d5e7;
}
.login-page{
  background: url('../../images/loginbg.jpg') top left no-repeat;
  height: -webkit-fill-available;
  background-size: cover;
  display: flex;
  align-items: center;
  padding-left: 0;
  min-height: 100vh !important;
}
.login-box{
  background: rgba(255,255,255,.2);
  padding: 2rem;
  text-align: center;
}

.input-field{
  position: relative;
}
.input-field i{
  position: absolute;
  top: 16px;
  font-size: 1.2rem;
  left: 1.2rem;
}
.input-field  .form-control{
  border-radius: 50px;
  height: auto;
  margin-bottom: 2rem;
  padding: .8rem;
  border: 0;
  padding-left: 3rem;
}
.logo-box h3{
  text-align: center;
  margin: 2rem 0;
  color: #fff;
}
.forgot-link{
  color: #fff;
  font-size: 14px;
  margin-top: 2rem;
  display: inline-block;
}
.remember{
  color: #fff;
  font-size: 14px;
}



/* Demo Stuff End -> */

/* <- Magic Stuff Start */



.login-btn{
  background-color: #003B5C !important;
  color: #fff;
  width: 100%;
  margin-top: 25px !important;
  height: 42px;
  border-radius: 10px !important;
}

.bottom-header{
    background: #324E87;
}
.bottom-header ul {
    text-align: left;
    padding: 0;
}
.bottom-header ul li{
    list-style: none;
    display: inline-block;
}
.bottom-header ul li a{
    color: #fff;
    font-size: .8rem;
    padding: .4rem;
    text-transform: uppercase;
    padding: 0 1rem;
}

.bacground-slider{
  position: fixed;
}
.customSliderBg {
  width:100%;
}
.customSliderBg img {
  width:100%;
}
.login-btn:hover {
  background-color: #1678c2 !important;
    color: #fff;
}
/*---SIDEBAR---*/



.sidebar-dark #sidebarToggle {
background-color: rgba(255,255,255,.2);
}
.sidebar #sidebarToggle {
width: 2.5rem;
height: 2.5rem;
text-align: center;
margin-bottom: 1rem;
cursor: pointer;
}
.dashboardpage{
display: flex;
height: 100%;
}
.checkbox-list{
padding: 1rem;
}
.tabOne, .tabTwo{
height: 48%;
padding: 0 15px;
}
.mapBox{
width: 100%;
height: 100%;
}
.mapBox img{
width: 100%;
height: 100%;
}

.nav-tabs .nav-link.active {
border: 0;
border-bottom: 2px solid #007bff;
}

.right-menu li a{
color: #3D73DD;
margin: 0px 0.4rem;
display: inline-block;
}
.right-menu li a span{
margin-left: .5rem;
color: #000;
font-weight: 600;
}

@media (min-width: 768px) {
  .sidebar {
    width: 20rem;
  }
}

.forgot-center {
  margin: auto;
}
