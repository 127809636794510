@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

.queue-block {
  background: #fff;
  padding: 18px 14px;
  box-shadow: 0 0 10px 0 #00000017;
  margin: 30px 0;
}
.queue-block h1 {
  font-size: 24px;
  color: #003b5b;
  font-weight: 600;
  margin: 0 0 16px 0;
}
.queue-inner {
  background: #f6fbff;
  border: solid 1px #d5e1eb;
  border-radius: 5px;
  padding: 14px 8px;
  text-align: center;
}
.queue-inner.ui.form input[type="text"] {
  width: 60%;
  text-align: center;
  box-shadow: 3px 3px 4px 0px #00000017;
  font-size: 16px;
  color: #003b5b;
  padding: 8px 0;
  border-radius: 8px;
}
.queue-inner h3 {
  background: #003b5b;
  border-radius: 5px;
  min-height: 58px;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  padding: 10px 5px;
  margin: 10px 0 0 0;
  border-radius: 8px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  display: flex;
}
.patientPageContainer h3 {
  font-size: 1.28571429rem;
  font-weight: bold;
}
.patientPageContainer h4 {
  font-size: 1.08rem;
  font-weight: bold;
}
.tab-content-area .ui.table thead th {
  background: #dff2ff;
  font-weight: 400;
  color: #003b5b;
}
.tab-table tr td {
  padding: 0.5rem 1rem !important;
}
.tab-table tr td:first-child {
  width: 15%;
}
.tab-table tr td:last-child {
  text-align: center;
  width: 15%;
}
.blue-btn {
  background: #003b5b;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  padding: 0.7rem 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}
.blue-btn:hover {
  background: #45b1ef;
}
/* tabs */
.tabsCustom {
  padding: 0px 0 0;
  box-shadow: 0 10px 20px rgb(133 133 133 / 13%), 0 6px 6px rgb(77 77 77 / 14%);
  margin: 30px 0 030px 0;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
}
.tabsCustom .nav.nav-pills {
  background: #003b5b;
}
.tabsCustom .tab-content {
  padding: 0 0 30px;
}
.tabsCustom .tab-content h3 {
  margin: 0;
}
.tabsCustom .table > thead > tr > th {
  word-break: initial;
}
.tabsCustom .fade:not(.show) {
  opacity: 1;
}
.tabsCustom .nav-item.active a,
.tabsCustom .nav-item.active a:hover,
.tabsCustom .nav-item.active a:focus {
  background-color: #45b1ef;
  color: #fff;
}
.tabsCustom .nav-item.active a,
.tabsCustom .nav-item a:hover,
.tabsCustom .nav-item a:focus {
  background-color: #45b1ef;
  color: #fff;
  border-bottom: 1px solid #45b1ef;
}
.tabsCustom .nav-item.active a {
  background-color: #45b1ef;
  color: #fff;
  border-bottom: 1px solid #45b1ef;
}
.tabsCustom .nav-item a {
  border: 0;
  color: #fff;
  background-color: #003b5b;
  border-right: 1px solid #4c607c;
  border-radius: 0;
  border-bottom: 1px solid #4c607c;
  margin: 0 -1px 0;
}

.edu label.fontn {
  font-weight: normal;
}

textarea.textareaf {
  width: 100%;
  height: 80px;
  padding: 10px;
}

.accordion .card-header {
  background: #003b5b;
  padding-bottom: 0;
  margin: 0px !important;
  padding: 0px !important;
}
.accordion .card-header h3 {
  color: #fff;
  margin: 6px 0 0 0;
}
.patientHeaderButton {
  margin-bottom: 0rem;
  float: right;
}
.patientHeaderButton .btn-primary {
  margin-right: 10px;
  background-color: #45b1ef;
  border: 1px solid #45b1ef;
  text-transform: uppercase;
  /* margin-bottom: 1rem; */
}
.wdt100 {
  width: 100px;
}
.wdt120 {
  width: 120px;
}
.wdt125 {
  width: 125px;
}
.postMedicalButtons {
  padding: 0 20px;
}
.postMedicalButtons .btn-primary {
  margin: 0 15px 15px 0;
}

.patientPageContainer {
  /* max-width: 1400px; */
  margin-left: auto !important;
  margin-right: auto !important;
}
.patientPageContainer {
  min-height: calc(100vh - 40px);
}
.skin-blue {
  background: #f6fbff !important;
}
.calcWidth {
  width: calc(100% - 22px);
}
.form-control {
  font-size: 0.9rem;
}

@media only screen and (max-width: 991px) {
  .tabsCustom .nav.nav-pills > li {
    width: 24.7%;
  }
}
@media only screen and (max-width: 767px) {
  .tabsCustom .container.tab-pane {
    max-width: 100%;
    width: 100%;
  }
  .tabsCustom ul {
    display: flex;
    flex-direction: column;
  }
  .tabsCustom ul li {
    width: 100% !important;
  }
  .patientHeaderButton {
    margin-bottom: 0rem;
    float: left;
  }
  .expandBody .sidebar-wrapper {
    left: 0;
  }
  .tabsouterCustom select {
    height: 40px;
    margin-bottom: 10px;
  }
  .tabsCustom .nav.nav-pills > li {
    width: 32.9%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .background-img {
    display: none;
  }
  .five.wide.column.ipad-fix.tab.only {
    width: 94% !important;
  }
}

.btnControl {
  width: 25px !important;
  height: 25px !important;
  padding: 0 !important;
  border-radius: 30px !important;
  margin-right: 5px !important;
}
.btnControl:last-child {
  margin-right: 0px !important;
}
.btnControl div {
  margin: 0 !important;
}
.btnControl .MuiSvgIcon-root {
  font-size: 1rem !important;
}
.btnControl.btndel {
  background: #d61313 !important;
  border: 1px solid #d61313 !important;
}
.btnControl.btnsv {
  background: #0db10d !important;
  border: 1px solid #0db10d !important;
}

.custom-select {
  background: #fff
    url(
      data:image/svg + xml,
      %3csvgxmlns="http://www.w3.org/2000/svg"width="4"height="5"viewBox="0 0 4 5"%3e%3cpathfill="%23343a40"d="M2 0L0 2h4zm0 5L0 3h4z"/%3e%3c/svg%3e
    )
    no-repeat right 0.75rem center/8px 10px;
}

.patientHeaderButton button {
  margin-right: 7px !important;
  margin-left: 0 !important;
}

textarea {
  resize: none;
}

/* 23-november */
.table {
  overflow: auto;
  white-space: nowrap;
  min-width: 1150px;
}
.table > tbody > tr > td {
  text-align: center;
  font-size: 0.9rem;
}
.unit-label {
  text-align: right;
  width: 100%;
  font-size: 10px;
}

/* 27-november*/
.shadow-box {
  background: #fff;
  padding: 10px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 3px 3px 4px 0px #00000017;
  margin-left: 15px;
}
.hr-blue {
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #73b4da !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}

/* 3-december */
.text-blue {
  color: #007bff;
}
.bg-gray {
  background: #f3f2f2;
}
.user-icon {
  height: 40px;
  width: 40px;
  line-height: 36px;
  border: 2px solid;
  border-radius: 30px;
}
table.RX-table {
  min-width: 1092px;
}
.form-inline label {
  align-items: flex-start !important;
  justify-content: flex-start;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 65;
}
.flex-7 {
  flex: 7;
}
.flex-8 {
  flex: 8;
}

.required_error {
  border: #d61313 solid 2px;
}

.selectWidth select {
  width: 175px;
}
.errorMsg2 {
  color: red;
  position: absolute;
  font-size: 12px;
}

.errorMsg {
  color: red;
  position: absolute;
  bottom: -18px;
  font-size: 12px;
}

.refillreminderblock {
  clear: both;
  margin-bottom: 30px;
}
#wrapper {
  height: auto !important;
}
